import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faFileContract } from '@fortawesome/free-solid-svg-icons';

class Footer extends Component {
    render() {
        const { data } = this.props;
        const publicUrl = process.env.PUBLIC_URL;
        const logoLink = publicUrl;

        return (
            <footer id="footer" className="p-70px-t p-30px-b footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer-top text-center p-30px-tb">
                                <a className="footer-logo" href={logoLink}>
                                    <img src={`${publicUrl}${data.footerlogo}`} alt="logo" style={{ width: '250px', height: 'auto' }}/>
                                </a>
                                <p>{data.footertext}</p>
                                <div className="social-network">
                                    <a href="/privacy-policy">
                                        <FontAwesomeIcon icon={faLock} className="social-icon" />
                                        <span> Privacy Policy</span>
                                    </a>
                                    <a href="/terms-of-service">
                                        <FontAwesomeIcon icon={faFileContract} className="social-icon" />
                                        <span> Terms of Service</span>
                                    </a>
                                </div>
                            </div>
                            <div className="footer-copyright p-30px-tb text-center">
                                <p dangerouslySetInnerHTML={{ __html: data.copyrighttext }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

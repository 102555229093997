import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import '../../css/srcStyle.css'

class Feature extends Component {
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL;
        let imgattr = 'image';
        return (
            <section id="feature" className="p-80px-tb position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2>{homedata.features.sectiontitle}</h2>
                            </div>
                            <div>
                                <strong>
                                    <span style={{ color: "black" }}>
                                        ✅ The AI Order Agent we set up for Soho Pizza on Lark St, Albany, NY has taken over 500 orders without any issues and without any human intervention.
                                        <br />
                                        ✅ It has been answering in 3-5 seconds and working 24/7.
                                        <br />
                                        ✅ It performs various actions during conversations such as address checks, creating payment links, printing tickets at the store, and scheduling orders.
                                        <br />
                                        ✅ It can handle multiple customers simultaneously in different languages if needed! (English, Spanish, Turkish, and many more)
                                        <br />
                                        ✅ It has even made funny jokes at the end of conversations.
                                        <br />
                                        🖥️ Soho Pizza staff can monitor all the conversations on an advanced web app user panel on our website. They can listen the the phone calls, see the chat history, and more.
                                        <br />
                                        ✅ Soho Pizza pays only $1.00 per order, which is cheaper than any other restaurant order-taking third party.
                                    </span>
                                </strong>
                            </div>
                            <div className="text-center mt-3" style={{ position: 'relative', zIndex: 10 }}>
                                <button
                                    onClick={() => this.openInNewTab('https://www.sohopizza.com/order-bot.html')}
                                    className="btn btn-default btn-filled animated-btn"
                                >
                                    Try Soho's AI Agent Now!
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 md-p-50px-b">
                            <div className="row">
                                {homedata.features.featurescontent.map((feature, i) =>
                                    <div key={i} className="col-sm-6">
                                        <div className="single-feature">
                                            <i className={feature.icon}></i>
                                            <h4>{feature.title}</h4>
                                            <p>{feature.content}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="position-relative featured-mockup">
                                <div className="bordered-box"></div>
                                <img className="phone-2" src={publicUrl + homedata.features.imgurl2} alt={imgattr} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Feature;

import React, { Component } from 'react';
import { HashLink as HLink } from 'react-router-hash-link';
import homedata from '../../data/homedata.json';

// Icon dosyalarını import edin
import websiteIcon from '../../assets/websiteicon.png';
import wpIcon from '../../assets/wpicon.png';
import instagramIcon from '../../assets/instagramicon.png';
import robotIcon from '../../assets/robot.jpg';

// Iconları bir nesnede toplayın
const icons = {
    'websiteicon.png': websiteIcon,
    'wpicon.png': wpIcon,
    'insagramicon.png': instagramIcon,
    'robot.png': robotIcon
};

class Services extends Component {
    render() {
        return (
            <section id="services" className="p-100px-tb sm-p-50px-b">
                <div className="custom-container">
                    <div className="row justify-content-md-center service-swiper">
                        <div className="col-12 text-center mb-5">
                            <h2>Our Services</h2>
                            <strong></strong><span style={{ color: "black" }}>
                            <p>At AI Bunny, we specialize in creating customized AI Virtual Assistants tailored to meet
                                the unique needs of small and mid-sized businesses. <br></br> Our AI Agents are designed to
                                automate your services, enhance customer experience, and boost your business
                                productivity. <br></br> You will be able monitor all the conversations, see all the reports and many more on an advanced web app user panel on our website. <br></br> Explore our range of services:</p></span>
                        </div>
                        {homedata.services.map((service, i) => (
                            <div key={i} className="col-lg-3 col-md-6 mb-4">
                                <div className="service-box text-center p-4 m-2">
                                    <img
                                        src={icons[service.icon]}
                                        alt={service.title}
                                        className={`service-icon mb-3 ${service.icon === 'websiteicon.png' ? 'website-icon' : ''}`}
                                    />
                                    <h4>{service.title}</h4>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        ))}
                        <div className="col-12 text-center mt-5">
                            <HLink smooth to={`/#contact`} className="btn btn-default btn-filled animated-btn">Contact
                                Now</HLink>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
